import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LoggerProvider } from './contexts/LoggerContext';
import { SpinnerProvider } from './contexts/SpinnerContext';
import { OrderProvider } from './contexts/OrderContext';
import { ProducstProvider } from './contexts/ProductsContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { bgBG } from '@mui/material/locale';
import { TagsProvider } from './contexts/TagsContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  bgBG,
);

root.render(
  // <React.StrictMode>

  <TagsProvider>
    <ProducstProvider>
  <OrderProvider>
    <SpinnerProvider>
      <LoggerProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </LoggerProvider>
    </SpinnerProvider>
    </OrderProvider>
    </ProducstProvider>
    </TagsProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
