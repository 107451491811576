function setNotification (message) {

    if (message === "Partner updated"){

        return {
            message: "Вашите данни бяха успешно актуализирани!",
            severity: "success"
        }
    }
    else if (message === "Partner Created") {

        return {
            message: "Успешна Регистрация!",
            severity: "success"
        }
    }
    else if (message.includes("already exists in another partner")) {
        return {
            message: "Партньорът вече съществува!",
            severity: "error"
        }
    }

    return {
        message: "Проблем със заявката! Моля опитайте по-късно!",
        severity: "error"
    }
}

export default setNotification;