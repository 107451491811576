
// const tagsColors = ['primary', 'secondary', 'success', 'error', 'info', 'warning'];

const tagsColors = [
    "#039be5", 
    "red", 
    "#00008B",
    "#bfc355",
    "#8B008B",
    "#008B8B",
    "#FF8C00",
    "#f6734b",
    "green"
  ];

export {tagsColors}