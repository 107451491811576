import { Button } from "@mui/material";
import { ShoppingCart } from "@mui/icons-material";
import { useContext } from "react";
import { SpinnerContext } from "../../contexts/SpinnerContext";

const PurchaseButton = ({
  handleClick,
  purchaseDisabled,
  setPurchaseDisabled,
}) => {
  const { setSpinner } = useContext(SpinnerContext);

  return (
    <Button
      flex={0.5}
      tag={"Продължаване с поръчката"}
      onClick={(e) => {
        setSpinner();
        setPurchaseDisabled(true);
        handleClick(e);
      }}
      color={"success"}
      variant="contained"
      endIcon={<ShoppingCart />}
      sx={{
        width: "50%",
        fontSize: "10px",
        fontWeight: "bold",
      }}
      disabled={purchaseDisabled}
    >Потвърди Поръчка
    </Button>
  );
};
export default PurchaseButton;
