import QuantityCell from "./QuantityCell";
import TotalPriceCell from "./TotalPriceCell";
import CustomTag from "./CustomTag.jsx";

const columnsConfig = [
  {
    field: "id",
    headerName: "Референция",
    flex: 0.5,
    type: "string",
  },

  {
    field: "barcode",
    headerName: "Баркод",
    flex: 0.8,
    type: "string",
    valueGetter: (value, row) => (row.barcode ? row.barcode : ""),
  },

  {
    field: "name",
    headerName: "Име",
    flex: 1.5,
    type: "string",
  },

  {
    field: "list_price",
    headerName: "Цена",
    flex: window.innerWidth < 1000 ? 0.1 : 0.8,
    cellClassName: "rightAlign",
    type: "number",
    renderCell: (params) => `${params.row.list_price} лв.`,
  },

  {
    field: "quantity",
    headerName: "Количество",
    width: window.innerWidth < 1000 ? 30 : 100,
    type: "number",
    align: "center",
    cellClassName: "rightAlign",
    renderCell: (params) => <QuantityCell params={params} />,
  },

  {
    field: "total_price",
    headerName: "Общо",
    width: window.innerWidth < 1000 ? 20 : 100,
    type: "number",
    cellClassName: "rightAlign",
    renderCell: (params) => <TotalPriceCell params={params} />, 
  },

  {
    id: "tag_ids",
    field: "tag_ids",
    headerName: "Тагове",
    flex: 3,
    renderCell: (params) => <CustomTag params={params}  />,
    valueGetter: (value, row) => row.tag_ids?.map((tag) => tag.id).join(", "),
  },
];

export default columnsConfig;
