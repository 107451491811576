const initialState = {
  uid: "",
  vat: "",
  name: "",
  email: "",
  phone: "",
  street: "",
};

const errorsState = {
  uid: false,
  vat: false,
  name: false,
  email: false,
  phone: false,
  street: false,
};

const regexDict = {
  street: "",
  name: "^(?!s*$).+",
  phone: "^(08\\d{8}|\\+359\\d{9})$",
  email:  "^[\\w\\-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
  uid: "",
  vat: "",
  zip: ""
};

const isFocused = {
  vat: false,
  name: false,
  email: false,
  phone: false,
  street: false,
  zip: false,
  city: false,
};

export { initialState, errorsState, regexDict, isFocused };
