import { createContext, useState } from "react";

const ProductsContext = createContext();
const ProducstProvider = ({ children }) => {

    const [products, setProducts] = useState([]);


  return (
    <ProductsContext.Provider value={{ products, setProducts}}>
      {children}
    </ProductsContext.Provider>
  );
};

export { ProducstProvider, ProductsContext };