import { useContext, useState } from "react";
import styles from "./HomeView.module.css";
import PartnerDetailsForm from "../PartnerDetails/PartnerDetailsForm";
import { SpinnerContext } from "../../contexts/SpinnerContext";
import { Alert } from "@mui/material";
import AlertDialog from "./alert";
import getPartner from "./getPartner";

const HomeView = () => {
  const [reference, setReference] = useState({ value: "" });
  const [isError, setIsError] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [renderDetails, setRenderDetails] = useState(false);
  const [renderSearch, setRenderSearch] = useState(true);
  const [partnerData, setPartnerData] = useState({});
  const { setSpinner } = useContext(SpinnerContext);

  function alertOpenClose() {
    return alertOpen ? setAlertOpen(false) : setAlertOpen(true);
  }

  function checkReturnCompany(partnersArr) {
    for (let partner of partnersArr) {
      if (partner.is_company) return partner;
    }
    return {}; // what to return if there is no company? Is such scenario possible?
  }

  async function onFormSubmit(e) {
    const formData = new FormData(e.target);
    try {
      const result = await getPartner(formData.get("partner_ref"), setIsError);

      if (result.status === "success") {
        setPartnerData({ ...checkReturnCompany(result.partners) }); // make sure that the 0th partner is always the parent
        setRenderDetails(true);
        setRenderSearch(false);
        return;
      }
      setAlertOpen(true);
    } catch (err) {
      setIsError(true);
    }
    finally {
      setSpinner();
    }
  }

  function onInputChange(e) {
    const { value } = e.target;
    setReference((ref) => ({ ...ref, value }));
  }

  return (
    <>
      {renderSearch && (
        <article>
          <AlertDialog open={alertOpen} alertOpenClose={alertOpenClose} />
          <form
            id="bulstat"
            className={styles.centered}
            onSubmit={(e) => {
              e.preventDefault();
              setSpinner();
              onFormSubmit(e);
            }}
          >
            <label htmlFor="partner_ref">
              Моля въведете Вашето ЕИК (Булстат):
            </label>
            <input
              type="text"
              name="partner_ref"
              onChange={onInputChange}
              value={reference.value}
              className={styles.idInput}
            />
            {isError && <Alert severity="error">Моля опитайте по-късно!</Alert>}
            <button className={"blueBtn"}>Въведи</button>
          </form>
        </article>
      )}
      {renderDetails && (
        <article>
          <PartnerDetailsForm partnerData={partnerData} isRegister={false} />
        </article>
      )}
    </>
  );
};

export default HomeView;
