async function getPartner(vatId) {
  const response = await fetch(
    "https://colibri.globalone.bg/api/partners/search",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ params: { vat: vatId } }),
    }
  );

  if (!response.ok) {
    throw new Error ('Problem with connection / response!')
  }
  
  const data = await response.json();
  return data.result;
}

export default getPartner;
