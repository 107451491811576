import { createContext, useEffect, useState } from "react";

const OrderContext = createContext();

const OrderProvider = ({ children }) => {
  const [orderState, setOrderState] = useState({});
  const [totalSum, setTotalSum] = useState(0);

  const orderSetter = (order) => {
    setOrderState(order);
  };

  useEffect(() => {
    let sum = 0;
    for (let key in orderState) {
      sum += orderState[key].total_price;
    }

    setTotalSum(sum);
  }, [orderState]);

  return (
    <OrderContext.Provider value={{ orderSetter, orderState, totalSum }}>
      {children}
    </OrderContext.Provider>
  );
};

export { OrderProvider, OrderContext };
