import Box from "@mui/material/Box";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import CustomButton from "./CustomButton";
import { TagsContext } from "../../contexts/TagsContext";
import { useContext } from "react";

function CustomToolbar({ handleFilter }) {
  const { tags } = useContext(TagsContext);
  const filterNames = Object.values(tags).sort((a, b) => {
    return b.name.localeCompare(a.name);
  });

  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: "Change density" } }}
      />
      <Box sx={{ flexGrow: 0.8 }} />
      <Box sx={{ flex: 10, display: "flex", gap: "10px" }}>
        {filterNames.map((tag) => (
          <Box key={tag.id} sx={{ flex: 1, display: "flex" }}>
            <CustomButton
              tagName={tag.name}
              handleClick={(e) => handleFilter(String(tag.id))}
            />
          </Box>
        ))}
        <Box sx={{ flex: 1, display: "flex" }}>
          <CustomButton
            tagName={"Изчисти Филтри"}
            handleClick={(e) => handleFilter("")}
          />
        </Box>
      </Box>
      <Box sx={{ flex: 1, display: "flex", height: "50px" }}></Box>
      <Box sx={{ flexGrow: 1 }} />
    </GridToolbarContainer>
  );
}

export default CustomToolbar;
