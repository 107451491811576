import Button from "@mui/material/Button";
import { TagsContext } from "../../contexts/TagsContext";
import { useContext} from "react";
import BackspaceIcon from '@mui/icons-material/Backspace';

const CustomButton = ({ tagName, handleClick }) => {
  const { tags } = useContext(TagsContext);
  return (
    <Button
      onClick={handleClick}
      flex="0.5"
      size="small"
      // color={tags[tag]}
      variant="contained"
      fullWidth="false"
      sx={{ padding: 0.2, fontSize: "10px", fontWeight: "bold", bgcolor: tags[tagName]?.color || 'darkred' }}
      endIcon={ tagName === 'Изчисти Филтри' ? <BackspaceIcon /> : '' }
    >
      {tagName}
    </Button>
  );
};
export default CustomButton;
