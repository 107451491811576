import { useContext, memo, useState } from "react";
import { OrderContext } from "../../contexts/OrderContext";
import { ProductsContext } from "../../contexts/ProductsContext";

const QuantityCell = ({ params }) => {
  const { orderSetter, orderState } = useContext(OrderContext);
  const { products } = useContext(ProductsContext);
  const [min, setMin] = useState(
    products.reduce((acc, curr) => {
      acc[curr.id] = 0;
      return acc;
    }, {})
  );

  function productMatch_PDF(barcode, quantity, e) {
    const PDF_barcode = String(barcode).concat("1");
    let PDF_id = null;

    const PDF_product = {
      quantity: 0,
      total_price: 0,
    };

    for (let product of products) {
      if (product.barcode !== PDF_barcode) continue;
      PDF_id = product.id;
      PDF_product["quantity"] = quantity < min[PDF_id] ? min[PDF_id] : quantity;
      PDF_product["total_price"] = PDF_product["quantity"] * product.list_price;
      break;
    }
    return { id: PDF_id, PDF_product };
  }

  return (
    <input
      id={params.row.id}
      style={{
        width: "80px",
        backgroundColor: "skyblue",
        opacity: "30%",
        height: "30px",
        fontWeight: "bold",
        fontSize: window.innerWidth < 600 ? "10px" : "15px",
      }}
      value={orderState[params.row.id]?.quantity || 0} // determineValue(orderState, params)
      type="number"
      min={0}
      onInput={(e) => {
        const quantity = Number(e.target.value);
        const { id, PDF_product } = productMatch_PDF(params.row.barcode, quantity, e);

        if (id !== null) {
          orderSetter((orders) => ({
            ...orders,
            [params.row.id]: {
              ...orders[params.row.id],
              quantity: quantity,
              total_price: quantity * params.row.list_price,
              barcode: params.row.barcode,
            },
            [id]: PDF_product,
          }));
        } else {
          orderSetter((orders) => ({
            ...orders,
            [params.row.id]: {
              ...orders[params.row.id],
              quantity: quantity,
              total_price: quantity * params.row.list_price,
              barcode: params.row.barcode,
            },
          }));
        }
        params.row.quantity = quantity;
        params.row.total_price = quantity * params.row.list_price;
      }}
      onFocus={(e) => e.target.classList.add("selected")}
      onBlur={(e) => e.target.classList.remove("selected")}
    />
  );
};

export default memo(QuantityCell);
