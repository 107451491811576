
const LogoComponent = ({ link }) => {

    return (

        <span>
            <img id="logo" src={link} alt="Colibri" />
        </span>
    )

}

export default LogoComponent;