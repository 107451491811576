import { bgBG } from "@mui/x-data-grid/locales";
import { createTheme } from "@mui/material/styles";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  bgBG
);

export default theme;
