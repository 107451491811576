import { useContext, memo} from "react";
import { OrderContext } from "../../contexts/OrderContext";

const TotalPriceCell = ({ params }) => {
    const { orderState } = useContext(OrderContext);

    return ( 
    
    <div>
        {Number(orderState[params.row.id]?.total_price.toFixed(2) || 0)} лв.
    </div>)
}

export default memo(TotalPriceCell)