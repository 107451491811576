import { Chip } from "@mui/material";
import { useContext } from "react";
import { TagsContext } from "../../contexts/TagsContext";

const CustomTag = ({ params }) => {
  const { tags } = useContext(TagsContext);
  return (
    <>
     { params.row.tag_ids?.map((tag) => {
     
      return   tag.id === 125 ? '' : <Chip key={tag.id} label={tag.name} size="small" 
      //color={tags[tag.name]}  
      sx={{bgcolor: tags[tag.name]?.color, color: 'white', fontWeight:'bold' }}
      
      />
     })}
    </>
  );
};

export default CustomTag;
