import { createContext, useState } from "react";

const SpinnerContext = createContext();

const SpinnerProvider = ({ children }) => {
  const [isSpinner, setIsSpinner] = useState(false);

  function setSpinner() {
    setIsSpinner((spinner) => (spinner ? false : true));
  }

  return (
    <SpinnerContext.Provider value={{ isSpinner, setSpinner }}>
      {children}
    </SpinnerContext.Provider>
  );
};

export { SpinnerProvider, SpinnerContext };
