async function createUpdateDetails(route, partnerDetailsParam) {
  const response = await fetch("https://colibri.globalone.bg/api/partners/" + route, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      params: {
        ...partnerDetailsParam,
      },
    }),
  });

  if (!response.ok) throw new Error("Problem with the connection / response!")
  const data = await response.json();
  const result = JSON.parse(data.result);

  return result;
}

export default createUpdateDetails;
