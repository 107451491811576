import { useContext } from "react";
import PurchaseButton from "./PurchaseButton";
import { OrderContext } from "../../contexts/OrderContext";
import AllertMessage from "./AlertMessage";
import { Box } from "@mui/material";

const PurchaseToolbar = ({
  handleClick,
  purchaseDisabled,
  setPurchaseDisabled,
  orderId,
  orderSuccess,
}) => {
  const { totalSum } = useContext(OrderContext);

  return (
    <Box className="purchaseToolbar">
      <Box className="innerContainer">
        <Box className="totalOrder">
          Общо поръчка: {totalSum.toFixed(2)} лв.
        </Box>
        <PurchaseButton
          handleClick={handleClick}
          purchaseDisabled={purchaseDisabled}
          setPurchaseDisabled={setPurchaseDisabled}
        />
      </Box>

      {(function () {
        if (orderSuccess === "default") {
          return;
        }
        if (orderSuccess) {
          return <AllertMessage
              status={"success"}
              message={`Поръчка с номер ${orderId} беше създадена!`}/>
        }

        return <AllertMessage
            status={"error"}
            message={"Поръчката не беше създадена! Моля пробвайте отново!"}
          />
      })()}
    </Box>
  );
};

export default PurchaseToolbar;
