import "./ProductsTable.css";
import { useContext, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { LoggerContext } from "../../contexts/LoggerContext.jsx";
import { SpinnerContext } from "../../contexts/SpinnerContext.jsx";
import { OrderContext } from "../../contexts/OrderContext.jsx";
import columnsConfig from "./columnsConfig.js";
import theme from "./theme.js";
import CustomToolbar from "./CustomFilter.jsx";
import { tagsColors } from "./TagsColors.js";
import { TagsContext } from "../../contexts/TagsContext.jsx";
import PurchaseToolbar from "./PurchaseToolbar.jsx";
import { useNavigate } from "react-router-dom";
import { ProductsContext } from "../../contexts/ProductsContext.jsx";

const ProductsTable = () => {
  const {products, setProducts} = useContext(ProductsContext);
  const { setTags } = useContext(TagsContext);
  const { isLogged } = useContext(LoggerContext);
  const { orderState } = useContext(OrderContext);
  const { setSpinner } = useContext(SpinnerContext);
  const [orderSuccess, setOrderSuccess] = useState("default");
  const [orderId, setOrderId] = useState("");
  const [purchaseDisabled, setPurchaseDisabled] = useState(false);
  const navigate = useNavigate();

  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const handleFilter = (filterValue) => {
    setFilterModel({
      items: [
        {
          field: "tag_ids",
          operator: "contains",
          value: filterValue,
        },
      ],
    });
  };

  useEffect(() => {

    if (isLogged === "") return;

    (async function () {
      const products = await getProducts();
      const availableTags = {};
      let availableColors = tagsColors.slice();
      const updatedProducts = products.map((product) => {
        for (let tag of product.tag_ids) {
          if (tag.id === 125) {
            continue;
          }

          if (availableColors.length <= 0) {
            availableColors = tagsColors.slice();
          }
          if (!availableTags[tag.name]) {
            availableTags[tag.name] = {name: tag.name, id: tag.id, color: availableColors.pop(), };
          }
        }
        return { ...product, quantity: 0, total_price: 0 };
      });

      setSpinner();
      setTags(availableTags);
      setProducts([...updatedProducts]);
    })();
  }, []);

  async function getProducts() {
    const response = await fetch(
      "https://colibri.globalone.bg/api/products/search_by_tags",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            tag_ids: [126, 127, 128, 129, 76, 77],
          },
        }),
      }
    );
    const data = await response.json();
    return data.result.products;
  }

  async function handleClick(e) {
    const line_ids = [];

    for (let key in orderState) {
      if (orderState[key].quantity > 0) {
        const id = key;
        const quantity = orderState[key].quantity;
        line_ids.push({
          [id]: quantity,
        });
      }
    }

    const response = await fetch(
      "https://colibri.globalone.bg/api/sale_orders/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          params: {
            partner_id: isLogged,
            line_ids,
          },
        }),
      }
    );

    if (!response.ok) {
      setOrderSuccess(false);
      setSpinner();
      setPurchaseDisabled(false);
      return;
    }

    const data = await response.json();

    //fix the endpoint responses formats
    if (Array.isArray(data.result)) {
      setOrderSuccess(false);
      setPurchaseDisabled(false);
      setSpinner();
      return;
    } 

    const result = JSON.parse(data.result);

    if (result.status === "success") {
      console.log(result.sale_order_id);
      setOrderSuccess(true);
      setOrderId(result.sale_order_id);
    } else {
      setOrderSuccess(false);
    }
    setSpinner();
    setPurchaseDisabled(false);
  }

 if (isLogged === "") {
  setTimeout(()=> {
    navigate('/')
  },3000);
  return (<div>Моля въведете Вашето ЕИК! Ще бъдете препратени към Начална страница!</div>)
 } 
 else {
  return (
    <>
      <PurchaseToolbar
        handleClick={handleClick}
        purchaseDisabled={purchaseDisabled}
        setPurchaseDisabled={setPurchaseDisabled}
        orderSuccess={orderSuccess}
        orderId={orderId}
      />
      <div className="gridContainer">
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={products}
            columns={columnsConfig}
            slots={{
              toolbar: () => {
                return <CustomToolbar handleFilter={handleFilter} />;
              },
            }}
            columnVisibilityModel={{
              id: false,
              barcode: window.innerWidth < 1000 ? false : true,
            }}
            filterModel={filterModel}
            onFilterModelChange={(newFilterModel) =>
              setFilterModel(newFilterModel)
            }
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }] },
                density: window.innerWidth < 1000 ? "compact" : "standard"
             
            }}
          />
        </ThemeProvider>
      </div>
    </>
  );
};
}
export default ProductsTable;
