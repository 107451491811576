import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";

function AlertDialog({ open, alertOpenClose }) {
  const navigate = useNavigate();

  
  function handleRegister() {
    alertOpenClose();
    navigate("/register");
  }

  return (
    <Dialog
      open={open}
      onClose={alertOpenClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Потребител с ЕИК не беше намерен!"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Моля проверете дали въведеният ЕИК е изписан правилно! Ако не сте
          регистрирани, моля натиснете бутон "Регистрация".
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={alertOpenClose} autoFocus>
          Затвори
        </Button>
        <Button onClick={handleRegister}>Регистрация</Button>
      </DialogActions>
    </Dialog>
  );
}
export default AlertDialog;
