function onFocus(e, setIsFocused) {
    const focusElName = e.target.tagName;
    const focusId = e.target.id;

    if (
      focusElName !== "INPUT" &&
      focusElName !== "SELECT" &&
      focusElName !== "TEXTAREA"
    )
      return;

    setIsFocused((dict) => ({ ...dict, [focusId]: true }));
  }

  function onFocusOut(e, setIsFocused) {
    const focusElName = e.target.tagName;
    const focusId = e.target.id;

    if (
      focusElName !== "INPUT" &&
      focusElName !== "SELECT" &&
      focusElName !== "TEXTAREA"
    )
      return;
    setIsFocused((dict) => ({ ...dict, [focusId]: false }));
  }

  export {onFocus, onFocusOut}