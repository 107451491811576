function validateInput(setterFunc, inputString, regExp, name, isErrorObj) {

    const regInit = new RegExp(regExp, 'gm');
    let isTrue = regInit.test(inputString);

    if (isTrue) {

        setterFunc({ ...isErrorObj, [name]: false });
        return;
    }
    setterFunc({ ...isErrorObj, [name]: true });
}   

function selectValidation(setterFunc, inputString, name, isErrorObj) {

    if (inputString === 'default') {
        setterFunc({ ...isErrorObj, [name]: true });
        return;
    }
    setterFunc({ ...isErrorObj, [name]: false });

}

export { validateInput, selectValidation}