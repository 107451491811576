import "./App.css";
import HomeView from "./views/HomeView/HomeView";
import LogoComponent from "./views/Logo/Logo";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProductsTable from "./views/ProductsTable/ProductsTable.jsx";
import { useContext } from "react";
import { SpinnerContext } from "./contexts/SpinnerContext.jsx";
import SpinnerComponent from "./views/Spinner/SpinnerComponent.jsx";
import PartnerDetailsForm from "./views/PartnerDetails/PartnerDetailsForm.jsx";

function App() {
  const { isSpinner } = useContext(SpinnerContext);

  return (
    <div className="App">
      <header className="App-header">
        <LogoComponent
          link={"/logo_colibri.png"}
        />
      </header>

      <main className="App-body">
        <BrowserRouter>
          {isSpinner && <SpinnerComponent />}
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/productsTable" element={<ProductsTable />} />
            <Route path="/register" element={<PartnerDetailsForm isRegister={true}/>} />
          </Routes>
        </BrowserRouter>
      </main>
    </div>
  );
}

export default App;
