import { createContext, useState } from "react";

const LoggerContext = createContext();

const LoggerProvider = ({ children }) => {

    const [isLogged, setIsLogged] = useState('');

    const loggerHandler = (partnerId) => {

        setIsLogged(partnerId);
    }


    return (

        <LoggerContext.Provider value={{ isLogged, loggerHandler }}>
            {children}
        </LoggerContext.Provider>

    )
}

export { LoggerProvider, LoggerContext }