import { useContext, useEffect, useState } from "react";
import styles from "./PartnerDetailsForm.module.css";
import {
  errorsState,
  isFocused as focusedDist,
  initialState,
} from "./PartnerFormModel";
import {
  validateInput,
  selectValidation,
} from "../../validations/inputValidation";
import { regexDict } from "./PartnerFormModel";
import { useNavigate } from "react-router-dom";
import { LoggerContext } from "../../contexts/LoggerContext";
import { SpinnerContext } from "../../contexts/SpinnerContext";
import { Alert } from "@mui/material";
import { onFocus, onFocusOut } from "./focus";
import setNotification from "./setNotification";
import createUpdateDetails from "./createUpdateDetails";

const PartnerDetailsForm = ({ partnerData, isRegister }) => {
  const { loggerHandler } = useContext(LoggerContext);
  const { setSpinner } = useContext(SpinnerContext);
  const [partnerDetails, setPartnerDetails] = useState({ ...initialState });
  const [isFocused, setIsFocused] = useState({ ...focusedDist });
  const [isErrors, setIsErrors] = useState({ ...errorsState });
  const [reqErr, setReqErr] = useState(false);
  const [requiredPopulated, setRequiredPopulated] = useState(null);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [regUpdMsg, setRegUpdMsg] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setPartnerDetails({ ...partnerData });
  }, [partnerData]);

  useEffect(() => {
    if (isRegister) return;

    const updateErr = { ...isErrors };
    for (let key in partnerDetails) {
      const regExp = new RegExp(regexDict[key], "g");
      updateErr[key] = !regExp.test(partnerDetails[key]);
    }

    if (partnerDetails['street'] === "" || partnerDetails['street'] === false){
      updateErr['street'] = true;
    }

    setIsErrors({ ...updateErr });
  }, [partnerDetails]);

  useEffect(()=> {

    if (!isRegister) return;

    

  },[partnerDetails])

  useEffect(() => {
    if (Object.values(isErrors).some((field) => field === true)) {
      setBtnDisabled(true);
      return;
    }
    setBtnDisabled(false);
  }, [isErrors]);

  function onProceed() {
    loggerHandler(partnerDetails.id);
    navigate("/productsTable");
  }

  async function onPartnerDetailsUpdate() {
    
    if (
      !partnerDetails["email"]||
       !partnerDetails["phone"] ||
       !partnerDetails["street"] ||
       !partnerDetails["name"]
     ) {
       setRequiredPopulated(false);
       setSpinner();
       return;
     } else {
       setRequiredPopulated(true);
     }
   
    const updateDetails = {
      id: partnerData["id"],
      email: partnerDetails["email"],
      phone: partnerDetails["phone"],
      street: partnerDetails["street"],
      zip: partnerDetails["zip"],
      city: partnerDetails["city"],
    };

    try {
      const result = await createUpdateDetails("update", updateDetails);
      setRegUpdMsg(setNotification(result.message));
      setBtnDisabled(false);
      setIsFormChanged(false);
    } catch (err) {
      setReqErr(true);
    } finally {
      setSpinner();
    }
  }

  async function onRegister() {
    const isFieldsErrors = Object.values(isErrors).some(
      (field) => field === true
    );

    if (isFieldsErrors) return;
   
    if (
     !partnerDetails["email"]||
      !partnerDetails["phone"] ||
      !partnerDetails["street"] ||
      !partnerDetails["name"]
    ) {
      setRequiredPopulated(false);
      setSpinner();
      return;
    } else {
      setRequiredPopulated(true);
    }

    try {
      const result = await createUpdateDetails("create", partnerDetails);
      if (result.status === "error") {
  
        setRegUpdMsg(setNotification(result.message));
        return;
      }

      setRegUpdMsg(setNotification(result.message));
      setTimeout(() => {
        setSpinner();
        navigate("/productsTable");
        loggerHandler(result.partner_id);
      }, 750);
    } catch (err) {
      setReqErr(true);
      
    }
    finally{
      setSpinner();
    }
  }

  function onFormChange(e) {
    const { name, value } = e.target;
    setIsFormChanged(true);
    setPartnerDetails({ ...partnerDetails, [name]: value });
  }

  function onFormBlur(e) {
    const { name, value, tagName } = e.target;

    if (tagName === "INPUT") {
      validateInput(setIsErrors, value, regexDict[name], name, isErrors);
    } else if (tagName === "SELECT") {
      selectValidation(setIsErrors, value, name, isErrors);
    }
  }

  function renderButtons() {
    if (isRegister) {
      return (
        <button
          className="blueBtn"
          onClick={async (e) => {
            setSpinner();
            e.preventDefault();
            await onRegister();
          }}
        >
          Регистрация
        </button>
      );
    } else if (!isRegister && !isFormChanged) {
      return (
        <button
          className="blueBtn"
          onClick={(e) => {
            setSpinner();
            e.preventDefault();
            onProceed(e);
          }}
          disabled={btnDisabled}
        >
          Продължи с покупка
        </button>
      );
    } else if (!isRegister && isFormChanged) {
      return (
        <button
          className="blueBtn"
          onClick={async(e) => {
            e.preventDefault();
            setSpinner();
            await onPartnerDetailsUpdate();
          }}
          disabled={btnDisabled}
        >
          Обнови детайли
        </button>
      );
    }
  }

  return (
    <div className={styles.formWrapper}>
      <form
        className={styles.partnerForm}
        onChange={onFormChange}
        onBlur={onFormBlur}
      >
        
        {reqErr && (
          <div className={styles.row}>
            <Alert severity="error">Моля опитайте по-късно!</Alert>
          </div>
        )}

        {regUpdMsg && (
          <div className={styles.row}>
            <Alert severity={regUpdMsg.severity}>{regUpdMsg.message}</Alert>
          </div>
        )}

        <div className={styles.row}>
          <div className={styles.container}>
            <input
              type="text"
              name="vat"
              id="vat"
              className={isFocused["vat"] ? styles.focusedInput : ""}
              readOnly={!isRegister}
              value={(function () {
                if (partnerDetails.vat) {
                  return partnerDetails.vat;
                } else if (partnerDetails.uid) {
                  return partnerDetails.uid;
                }
                return "";
              })()}
              onMouseEnter={(e) => onFocus(e, setIsFocused)}
              onMouseLeave={(e) => onFocusOut(e, setIsFocused)}
              required
            />
            <label
              htmlFor="vat"
              className={
                isFocused.vat || partnerDetails.vat || partnerDetails.uid
                  ? styles.focused
                  : ""
              }
            >
              *ЕИК (Булстат):
            </label>
            <div className={isErrors.vat ? `errorBox visible` : "errorBox"}>
              Моля въведете правилно ЕИК!
            </div>
          </div>

          <div className={styles.container}>
            <input
              type="text"
              name="name"
              id="name"
              className={isFocused["name"] ? styles.focusedInput : ""}
              readOnly={!isRegister}
              value={partnerDetails.name ? partnerDetails.name : ""}
              onMouseEnter={(e) => onFocus(e, setIsFocused)}
              onMouseLeave={(e) => onFocusOut(e, setIsFocused)}
              required
            />
            <label
              className={
                isFocused.name || partnerDetails.name ? styles.focused : ""
              }
              htmlFor="name"
            >
              *Име (Юридическо / Физическо лице)
            </label>
            <div className={isErrors.name ? `errorBox visible` : "errorBox"}>
              Моля въведете две имена!
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.container}>
            <input
              type="text"
              name="email"
              id="email"
              className={isFocused["email"] ? styles.focusedInput : ""}
              value={partnerDetails.email ? partnerDetails.email : ""}
              onMouseEnter={(e) => onFocus(e, setIsFocused)}
              onMouseLeave={(e) => onFocusOut(e, setIsFocused)}
            />
            <label
              htmlFor="email"
              className={
                isFocused.email || partnerDetails.email ? styles.focused : ""
              }
            >
              *Имейл{" "}
            </label>
            <div className={isErrors.email ? `errorBox visible` : "errorBox"}>
              Моля въведете правилен имейл!
            </div>
          </div>

          <div className={styles.container}>
            <input
              type="text"
              name="phone"
              id="phone"
              className={isFocused["phone"] ? styles.focusedInput : ""}
              value={partnerDetails.phone ? partnerDetails.phone : ""}
              onMouseEnter={(e) => onFocus(e, setIsFocused)}
              onMouseLeave={(e) => onFocusOut(e, setIsFocused)}
            />
            <label
              htmlFor="mobile"
              className={
                isFocused.phone || partnerDetails.phone ? styles.focused : ""
              }
            >
              *Телефон за връзка:
            </label>
            <div className={isErrors.phone ? `errorBox visible` : "errorBox"}>
              Моля въведете телефон започващ с +359 или 08!
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.container}>
            <input
              type="text"
              name="street"
              id="street"
              className={isFocused["street"] ? styles.focusedInput : ""}
              value={partnerDetails.street ? partnerDetails.street : ""}
              onMouseEnter={(e) => onFocus(e, setIsFocused)}
              onMouseLeave={(e) => onFocusOut(e, setIsFocused)}
            />
            <label
              htmlFor="street"
              className={
                isFocused.street || partnerDetails.street ? styles.focused : ""
              }
            >
              *Адрес/Улица:
            </label>
            <div className={isErrors.street ? `errorBox visible` : "errorBox"}>
              Моля въведете адрес/улица!
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.container}>
            <input
              type="text"
              name="zip"
              id="zip"
              className={isFocused["zip"] ? styles.focusedInput : ""}
              value={partnerDetails.zip ? partnerDetails.zip : ""}
              onMouseEnter={(e) => onFocus(e, setIsFocused)}
              onMouseLeave={(e) => onFocusOut(e, setIsFocused)}
            />
            <label
              htmlFor="zip"
              className={
                isFocused.zip || partnerDetails.zip ? styles.focused : ""
              }
            >
              Пощенски код
            </label>
            <div className={isErrors.zip ? `errorBox visible` : "errorBox"}>
              Моля въведете пощенски код!
            </div>
           
            <div
              className={
                requiredPopulated === false ? `errorBox visible` : "errorBox"
              }
            >
              Моля попълнете всички полета!
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.container}>
              <input
                type="text"
                name="city"
                id="city"
                className={isFocused["city"] ? styles.focusedInput : ""}
                value={partnerDetails.city ? partnerDetails.city : ""}
                onMouseEnter={(e) => onFocus(e, setIsFocused)}
                onMouseLeave={(e) => onFocusOut(e, setIsFocused)}
              />
              <label
                htmlFor="zip"
                className={
                  isFocused.city || partnerDetails.city ? styles.focused : ""
                }
              >
                Град
              </label>
              <div className={isErrors.city ? `errorBox visible` : "errorBox"}>
                Моля въведете град!
              </div>
            </div>
          </div>
        </div>  
        <div className={styles.row}>{renderButtons()}</div>
     
      </form>
    </div>
  );
};

export default PartnerDetailsForm;
